<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>慈溪市规划局</span>
          <div>
            <el-button type="primary" v-if="!readOnly" @click="onSubmit"
            >保存</el-button
          >
          <el-button type="warning" @click="print">打印</el-button>
          </div>
          </div>
      </FloatTitBtn>
      <div class="edit-container">
        <el-form
          :disabled="readOnly"
          ref="baseform"
          label-position="left"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="文号:">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="函复标题:">
                <el-input v-model="form.HFBT"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="函复单位:">
                <el-input v-model="form.HFDW"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="函复内容:">
                <el-input
                  v-model="form.HFNR"
                  type="textarea"
                  :rows="10"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <span></span>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发函日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.FHRQ"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-show="false">
      <div id="forms" style="line-height: 30px;padding-right: 100px;padding-left: 100px">
        <div style="font-size: 25px;display: flex;justify-content: center;margin-top: 40px;font-family:'微软雅黑';">慈溪市自然资源和规划局</div>
        <div style="text-align: left;font-size: 14px;">
          <div style="font-size:14px;text-align: center;margin-top:25px">{{form.WH}}</div>
          <div style="border-top:1px solid #999999;width:92%;margin:10px auto;"></div>
          <div style="font-size:15px;text-align: center;font-weight:bold;">{{form.HFBT}}</div>
          <div>{{form.HFDW}}:</div>
          <div v-for="(v, i) in cllist" :key="i" style="font-size: 14px;line-height:2.2;">
            <span>&emsp;&emsp;{{ v }}</span>
          </div>
          <div>&emsp;&emsp;特此函复。</div>
          <div style="padding-top:30px;font-size: 14px;">
          <div style="float: right;line-height:1.6;">
            <p>慈溪市自然资源和规划局</p>
            <div style="text-indent: 2em">{{getDate2(form.FHRQ)}}</div>
          </div >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import dayjs from 'dayjs';
import print from "print-js";
import { ElMessage } from 'element-plus'

export default {
  name: 'han',
  components: { FloatTitBtn },
  data() {
    return {
      form: {
        WH: '',
        HFBT: '',
        HFDW: '',
        HFNR: '',
        FHRQ: this.getDate(new Date()),
      },
      cllist: ['', '', ''],
      readOnly: false
    };
  },
  methods: {
    print() {
      print({
        printable: "forms",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
    getDate(date) {
      var a = date;
      a = dayjs(date).format('YYYY-MM-DD');
      if (date == null || date == '' || date == undefined) {
        return '';
      } else {
        return a;
      }
    },
    getDate2(date) {
      var a = date;
      a = dayjs(date).format('YYYY年MM月DD日');
      if (date == null || date == '' || date == undefined) {
        return '';
      } else {
        return a;
      }
    },
    async onSubmit() {
      let me = this
      await this.$ajax
        .post(
          this.$appConfig.apiUrls.ywxtApi + '/api/YW/AddOrUpdateHAN',
          this.form
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form.Id = response.data.Data;
            me.cllist = me.form.HFNR.split('\n')
            ElMessage({
              type: 'success',
              message: '保存成功!',
            })
          } else {
            ElMessage({
              type: 'info',
              message: '保存失败!',
            })
            console.log(response.data.ErrorMessage)
          }
        })
    },
    async fetch(Id) {
      let me = this;
      try {
        await this.$ajax
          .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/GetHAN?BJID=" + Id)
          .then((response) => {
            if (response.data.IsSuccess) {
              if (response.data.Data && response.data.Data.Id) {
                me.form = response.data.Data;
                if(me.form.HFNR){
                  me.cllist = me.form.HFNR.split('\n')
                }
                
              }
            } else {
              throw response.data.ErrorMessage;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    if (!this.$route.query.taskId) {
      this.readOnly = true;
    }
    this.fetch(this.form.BJID);
  },

};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
</style>
